<template>
  <base-section id="theme-features">
    <base-section-heading title="Features">
      SwaVan is browser extension which help to redirect the network request
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col
          v-for="(feature, i) in features"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
          >
            {{ feature.description }}
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionThemeFeatures',

    data: () => ({
      features: [
        {
          title: 'Developer friendly UI',
          icon: 'mdi-fountain-pen-tip',
          description: 'SwaVan user interface is simple, Developers have choice to work either in Popup view or in new window',
        },
        {
          title: 'Mock data',
          icon: 'mdi-database',
          description: 'SwaVan allow developers to create, edit or delete the mock data, Developer can either choose to store mock data locally or in Mocky.io`s server',
        },
        {
          title: 'Host',
          icon: 'mdi-link',
          description: 'Developer have an option to apply all redirect rule to specific host only',
        },
        {
          title: 'Resource',
          icon: 'mdi-file',
          description: 'Developer have an option to apply all redirect rule to specific resource type (i.e. main_frame, xmlhttprequest)',
        },
        {
          title: 'Unlimited Rules',
          icon: 'mdi-infinity',
          description: 'All rules created using SwaVan are stored locally, so as long as storage support developer can add any number of rules',
        },
        {
          title: 'Hot Reloading',
          icon: 'mdi-refresh',
          description: 'Developer can enable reload on action feature, it reload the page each time rules got added, edited or deleted',
        },
        {
          title: 'Toggle',
          icon: 'mdi-toggle-switch',
          description: 'SwaVan allow to toggle ON and OFF multiple features like Rules, responses and filters',
        },
        {
          title: 'Sharing',
          icon: 'mdi-share-variant',
          description: 'SwaVan allow sharing rule through copy and paste',
        },
      ],
    }),
  }
</script>
